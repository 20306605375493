import type { FC, ReactNode } from "preact/compat";
import { useEffect, useRef, useState } from "preact/hooks";
import { useClickOutside } from "@hooks/useClickOutside";
import { SingleSelectListOption } from "../ComboboxListOption";
import { ComboboxGroupButton } from "../ComboboxButton";
import { ComboboxListbox } from "../ComboboxListbox";
import { ComboboxSearchInput } from "../ComboboxSearchInput";

export interface ComboboxButtonWrapperProps {
  optional?: boolean;
  placeholder?: string;
  label?: string;
  isMobile?: boolean;
  required?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  variant?: "contained" | "outlined";
  button?: "select" | "submit";
  buttonWrapperClass?: string;
  selectedItem?: {
    name: string;
    label: string;
  } | null;
  data: {
    name: string;
    label: string;
    searched?: boolean;
    selected?: boolean;
    filtered?: boolean;
  }[];
  open?: boolean;
  error?: string;
  handleOpenClick?: (open: boolean) => void;
  handleGetSelectedItems?: (
    items: {
      label: string;
      name: string;
      selected: boolean;
    }[],
  ) => void;
  handleGetSelectedItem?: (item: {
    label: string;
    name: string;
    selected: boolean;
  }) => void;
}
export const ComboboxButtonWrapper: FC<ComboboxButtonWrapperProps> = ({
  data,
  handleGetSelectedItem,
  optional,
  handleOpenClick,
  open,
  placeholder,
  label,
  selectedItem,
  isMobile,
  startIcon,
  endIcon,
  variant = "outlined",
  button = "select",
  buttonWrapperClass,
  error
}) => {
  const comboBoxRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [items, setItems] = useState<
    {
      label: string;
      name: string;
      selected: boolean;
      searched?: boolean;
      filtered?: boolean;
    }[]
  >(
    data.map((_item: any) => {
      return {
        ..._item,
        selected: false,
      };
    }),
  );

  useEffect(() => {
    if (JSON.stringify(items) !== JSON.stringify(data)) {
      setItems(
        items.map((_item) => {
          const isFiltered = data.find(
            (_v) => _item.name === _v.name && _v.filtered,
          );
          if (isFiltered) {
            return {
              ..._item,
              filtered: true,
              searched: false,
            };
          }
          return {
            ..._item,
            filtered: false,
          };
        }),
      );
    }
  }, [data]);

  useEffect(() => {
    if (selectedItem) {
      setItems(
        items.map((_item) => {
          if (_item.name === selectedItem.name) {
            return { ..._item, selected: true };
          }
          return {
            ..._item,
            selected: false,
          };
        }),
      );
    }
  }, [selectedItem?.label, selectedItem?.name]);

  const handleClick = () => {
    handleOpenClick?.(!open);
  };
  const handleClose = () => {
    handleOpenClick?.(false);
  };

  useClickOutside(comboBoxRef, () => {
    if (!isMobile) {
      handleClose();
    }
  });
  const handleSelectOption = (
    option: { name: string; label: string },
    checked: boolean,
  ) => {
    setSearchTerm("");
    const updatedItems = items.map((_item) => {
      if (_item.name === option.name) {
        return { ..._item, selected: checked };
      }
      return {
        ..._item,
        selected: false,
      };
    });
    setItems(updatedItems);
    const selected = updatedItems.find((_item) => _item.name === option.name);
    if (selected) {
      handleGetSelectedItem?.(selected);
    }
  };

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);
    if (term) {
      const filteredItems = items.map((_item) => {
        if (_item.label.toLowerCase().includes(term.toLowerCase())) {
          return {
            ..._item,
            searched: true,
          };
        }

        return {
          ..._item,
          searched: false,
        };
      });
      setItems(filteredItems);
    } else {
      setItems(items.map((_item) => ({ ..._item, searched: false })));
    }
  };

  const renderedItems = () => {
    const fItems = items.filter((_item) => _item.filtered);
    if (searchTerm) {
      let sItems: {
        label: string;
        name: string;
        selected: boolean;
        searched?: boolean;
        filtered?: boolean;
      }[] = [];

      if (fItems.length) {
        sItems = items.filter((_item) => {
          return _item.searched && _item.filtered;
        });
      }
      if (!fItems.length) {
        sItems = items.filter((_item) => {
          return _item.searched;
        });
      }
      return sItems.map((_v) => (
        <SingleSelectListOption
          handleClose={handleClose}
          key={_v.name}
          name={_v.name}
          label={_v.label}
          selected={_v.selected}
          handleSelectOption={handleSelectOption}
        />
      ));
    }
    if (fItems.length) {
      return fItems.map((_v) => (
        <SingleSelectListOption
          handleClose={handleClose}
          key={_v.name}
          name={_v.name}
          label={_v.label}
          selected={_v.selected}
          handleSelectOption={handleSelectOption}
        />
      ));
    }

    if (!searchTerm && !fItems.length) {
      return items.map((_v) => (
        <SingleSelectListOption
          handleClose={handleClose}
          key={_v.name}
          name={_v.name}
          label={_v.label}
          selected={_v.selected}
          handleSelectOption={handleSelectOption}
        />
      ));
    }
  };

  return (
    <ComboboxGroupButton
      placeholder={placeholder}
      buttonWrapperClass={buttonWrapperClass}
      label={label}
      button={button}
      onClick={handleClick}
      ref={comboBoxRef}
      open={open}
      error={error}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant}
      labels={items
        .filter((_item) => _item.selected)
        .map((_item) => _item.label)}
    >
      {({ open }) => {
        {
          return open ? (
            <ComboboxListbox
              handleClose={handleClose}
              placeholder={placeholder}
              optional={optional}
              isMobile={isMobile}
              items={items.filter((_item) => _item.filtered)}
            >
              <ComboboxSearchInput
                term={searchTerm}
                handleSearchChange={handleSearchChange}
              />
              <>{renderedItems()}</>
            </ComboboxListbox>
          ) : null;
        }
      }}
    </ComboboxGroupButton>
  );
};
