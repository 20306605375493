import type { FC, ReactNode } from 'preact/compat'
import classnames from 'classnames'
import { useEffect, useRef, useState } from 'preact/hooks'
import { useClickOutside } from '@hooks/useClickOutside'

import { ComboboxGroupButton } from '../ComboboxButton'
import { ComboboxListbox } from '../ComboboxListbox'

export interface MultiSelectGroupedListContainerProps {
  optional?: boolean
  placeholder?: string
  isGroupedSpecialties?: boolean
  label?: string
  isMobile?: boolean
  required?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
  variant?: 'contained' | 'outlined'
  button?: 'select' | 'submit'
  buttonWrapperClass?: string
  selectedItem?: {
    name: string
    label: string
  } | null
  data: {
    name: string
    label: string
    searched?: boolean
    selected?: boolean
    filtered?: boolean
    specialties: {
      name: string
      label: string
      groups?: Array<{
        value: string
        label: string
      }>
    }[]
  }[]
  open?: boolean
  handleOpenClick?: (open: boolean) => void
  handleGetSelectedItems?: (
    items: {
      label: string
      name: string
      selected: boolean
    }[]
  ) => void
  handleGetSelectedItem?: (item: {
    label: string
    name: string
    selected: boolean
  }) => void
}
export const MultiSelectGroupedListContainer: FC<
  MultiSelectGroupedListContainerProps
> = ({
  data,
  handleGetSelectedItem,
  handleGetSelectedItems,
  optional,
  handleOpenClick,
  open,
  placeholder,
  label,
  selectedItem,
  isMobile,
  startIcon,
  endIcon,
  variant = 'outlined',
  button = 'select',
  buttonWrapperClass
}) => {
  const comboBoxRef = useRef(null)
  const [items, setItems] = useState<
    {
      label: string
      name: string
      selected: boolean
      searched?: boolean
      filtered?: boolean
      specialties: {
        name: string
        label: string
        groups?: Array<{
          value: string
          label: string
        }>
      }[]
    }[]
  >(
    data.map((_item: any) => {
      return {
        ..._item,
        selected: false
      }
    })
  )

  useEffect(() => {
    if (JSON.stringify(items) !== JSON.stringify(data)) {
      setItems(
        items.map((_item) => {
          const isFiltered = data.find(
            (_v) => _item.name === _v.name && _v.filtered
          )
          if (isFiltered) {
            return {
              ..._item,
              filtered: true,
              searched: false
            }
          }
          return {
            ..._item,
            filtered: false
          }
        })
      )
    }
  }, [data])

  useEffect(() => {
    if (selectedItem) {
      setItems(
        items.map((_item) => {
          if (_item.name === selectedItem.name) {
            return { ..._item, selected: true }
          }
          return {
            ..._item,
            selected: false
          }
        })
      )
    }
  }, [selectedItem?.label, selectedItem?.name])

  const handleClick = () => {
    handleOpenClick?.(!open)
  }
  const handleClose = () => {
    handleOpenClick?.(false)
  }

  useClickOutside(comboBoxRef, () => {
    if (!isMobile) {
      handleClose()
    }
  })
  const handleSelectOption = (
    option: { name: string; label: string },
    checked: boolean
  ) => {
    let updatedItems = []
    if (checked) {
      if (option.name === 'other') {
        updatedItems = items.map((_item) => ({
          ..._item,
          selected: _item.name === option.name ? true : false
        }))
        setItems(updatedItems)
      } else {
        updatedItems = items.map((_item) => {
          if (_item.name === 'other') {
            return {
              ..._item,
              selected: false
            }
          } else {
            return {
              ..._item,
              selected: _item.name === option.name ? true : _item.selected
            }
          }
        })
        setItems(updatedItems)
      }
    } else {
      updatedItems = items.map((_item) => ({
        ..._item,
        selected: _item.name === option.name ? false : _item.selected
      }))
      setItems(updatedItems)
    }
    const selected = updatedItems.filter((_item) => _item.selected)
    const needs = selected.reduce((prev: any, curr) => {
      return [...prev, ...curr.specialties]
    }, [])
    const set = new Set(needs.map((_need: any) => _need.name))
    handleGetSelectedItems?.(
      Array.from(set).map((_name) => {
        const need = needs.find((_need: any) => _need.name === _name)
        return need
      })
    )
  }

  return (
    <ComboboxGroupButton
      placeholder={placeholder}
      buttonWrapperClass={buttonWrapperClass}
      label={label}
      button={button}
      onClick={handleClick}
      ref={comboBoxRef}
      open={open}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant}
      labels={items
        .filter((_item) => _item.selected)
        .map((_item) => _item.label)}
    >
      {({ open }) => {
        {
          return open ? (
            <ComboboxListbox
              handleClose={handleClose}
              placeholder={placeholder}
              optional={optional}
              isMobile={isMobile}
              items={items.filter((_item) => _item.filtered)}
            >
              <>
                {items?.map((_group) => {
                  return (
                    <li
                      onClick={() => {
                        handleSelectOption(
                          {
                            name: _group.name,
                            label: _group.label
                          },
                          !_group?.selected
                        )
                        // handleOpenClick?.(!open)
                      }}
                      class={classnames(
                        'flex flex-col cursor-pointer hover:bg-gt-slate-light hover:bg-opacity-20',
                        {
                          'bg-gt-slate-light bg-opacity-20': _group?.selected
                        }
                      )}
                      role='group'
                      key={_group.name}
                    >
                      <p className='font-normal text-medium flex items-center px-4 h-12 ml-0'>
                        <input
                          className='mx-2 my-1 text-lg fas accent-gt-lilactext-lg cursor-pointer'
                          type='checkbox'
                          checked={_group.selected}
                          aria-hidden='true'
                        />
                        <span
                          className='flex w-full h-full items-center gap-4'
                        >
                          <span>{_group.label}</span>
                        </span>
                      </p>
                    </li>
                  )
                })}
              </>
            </ComboboxListbox>
          ) : null
        }
      }}
    </ComboboxGroupButton>
  )
}
