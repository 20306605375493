import type { FC } from "preact/compat";
import classnames from "classnames";
import Icons from "@components/icons.ts";
import { Filters } from "../Filters";
import { ComboboxGroupedButtons } from "@components/ComboBoxes/ComboboxButtonGroup";
import { ComboboxButtonWrapper } from "@components/ComboBoxes/ComboboxButtonWrapper";
import { ComboboxButtonMultiSelect } from "@components/ComboBoxes/ComboboxButtonMultiSelect";
import { ComboboxGroupButton } from "@components/ComboBoxes/ComboboxButton";

export const FilterComponentGroup: FC<{
  states: Array<{ name: string; label: string; short_name: string }>;
  specialties: Array<{ name: string; label: string }>;
  isGroupedSpecialties?: boolean;
  groupedSpecialties?: Array<{
    group: string;
    name: string;
    label: string;
    specialties: {
      name: string;
      label: string;
      groups?: Array<{
        value: string;
        label: string;
      }>;
    }[];
  }>;
  optional_filter_props?: Array<"state" | "needs" | "insurance">;
  insurance: Array<{
    name: string;
    label: string;
    states: Array<{ short_name: string }>;
  }>;
  filter_buttons_props?: Array<{
    name: string;
    label: string;
    is_optional: boolean;
    is_multi_select: boolean;
    placeholder: string;
  }>;
}> = ({
  specialties: specialty,
  states: statesFilters,
  insurance,
  optional_filter_props,
  filter_buttons_props,
  groupedSpecialties,
  isGroupedSpecialties,
}) => {
  return (
    <Filters
      specialties={specialty}
      states={statesFilters}
      insurance={insurance}
      optional_filter_props={optional_filter_props}
      filter_buttons_props={filter_buttons_props}
    >
      {({
        selectedItems,
        handleRedirectClick,
        selectLater,
        handleSelectLater,
        isMobile,
        insurance,
        specialty,
        statesFilters,
        handleOpenStates,
        handleOpenInsurance,
        handleOpenNeeds,
        handleGetSelectedInsurance,
        handleGetSelectedState,
        openInsurance,
        openNeeds,
        openStates,
        handleGetSelectedNeeds,
        errors,
      }) => {
        return (
          <div className='relative w-full'>
            <ComboboxGroupedButtons>
              <ComboboxButtonWrapper
                optional={filter_buttons_props?.[0]?.is_optional}
                handleOpenClick={handleOpenStates}
                buttonWrapperClass={classnames(
                  "w-11/12 md:rounded-s-lg border-b md:border my-2 md:my-0",
                  {
                    "border-[#B35436]": errors?.state,
                    "border-gt-black": !errors?.state,
                  },
                )}
                open={openStates}
                error={errors?.state}
                selectedItem={selectedItems.state}
                isMobile={isMobile}
                endIcon={<Icons.CaretDownIcon />}
                data={
                  selectedItems.insurance
                    ? statesFilters.map((_state) => {
                        const states = insurance.find(
                          (_insurance) =>
                            _insurance.name === selectedItems.insurance?.name,
                        )?.states;

                        const hasStates = states?.find(
                          (_s) =>
                            _s.short_name.toLowerCase() ===
                            _state.short_name.toLowerCase(),
                        );
                        if (hasStates) {
                          return {
                            ..._state,
                            filtered: true,
                          };
                        }
                        return _state;
                      })
                    : statesFilters
                }
                placeholder={
                  filter_buttons_props?.[0]
                    ? `${filter_buttons_props[0].placeholder}`
                    : `Select your state`
                }
                label={
                  filter_buttons_props?.[0]
                    ? `${filter_buttons_props[0].label}${filter_buttons_props[0].is_optional ? "" : "*"}`
                    : `location`
                }
                handleGetSelectedItem={handleGetSelectedState}
              />

              <ComboboxButtonWrapper
                optional={filter_buttons_props?.[1]?.is_optional}
                handleOpenClick={handleOpenInsurance}
                open={openInsurance}
                error={errors?.insurance}
                buttonWrapperClass={classnames(
                  "w-11/12 md:border-t border-b md:border-r my-2 md:my-0",
                  {
                    "border-[#B35436]": errors?.insurance,
                    "border-gt-black": !errors?.insurance,
                  },
                )}
                selectedItem={selectedItems.insurance}
                isMobile={isMobile}
                endIcon={<Icons.CaretDownIcon />}
                data={
                  // find insurance with the selected state
                  selectedItems.state
                    ? insurance.map((_insurance) => {
                        const insurerHasSelectedState = _insurance.states.find(
                          (_s) =>
                            _s.short_name.toLowerCase() ===
                            selectedItems.state?.short_name?.toLowerCase(),
                        );

                        if (insurerHasSelectedState) {
                          return {
                            ..._insurance,
                            filtered: true,
                          };
                        }
                        return _insurance;
                      })
                    : insurance
                }
                placeholder={
                  filter_buttons_props?.[1]
                    ? `${filter_buttons_props[1].placeholder}`
                    : `Select your carrier`
                }
                label={
                  filter_buttons_props?.[1]
                    ? `${filter_buttons_props[1].label}${filter_buttons_props[1].is_optional ? "" : "*"}`
                    : `insurance`
                }
                handleGetSelectedItem={handleGetSelectedInsurance}
              />
              <ComboboxButtonMultiSelect
                isMobile={isMobile}
                isGroupedSpecialties={isGroupedSpecialties}
                handleOpenClick={handleOpenNeeds}
                buttonWrapperClass='border-gt-black w-11/12 md:border-t border-b md:border-r my-2 md:my-0'
                open={openNeeds}
                specialties={specialty}
                {...(groupedSpecialties && {
                  groupedSpecialties,
                })}
                endIcon={<Icons.CaretDownIcon />}
                placeholder={
                  filter_buttons_props?.[2]
                    ? `${filter_buttons_props[2].placeholder}`
                    : `Select your needs`
                }
                label={
                  filter_buttons_props?.[2]
                    ? `${filter_buttons_props[2].label}${filter_buttons_props[2].is_optional ? "" : "*"}`
                    : `needs`
                }
                optional={filter_buttons_props?.[2]?.is_optional}
                handleGetSelectedItems={handleGetSelectedNeeds}
                selectedItems={selectedItems?.specialty}
                selectLater={selectLater}
                handleSelectLater={handleSelectLater}
              />

              <ComboboxGroupButton
                variant='contained'
                button='submit'
                buttonWrapperClass='border-gt-black w-11/12 rounded-e-lg md:border-t md:border-b md:border-r md:border-l-0 my-4 md:my-0'
                label={
                  <div className='flex items-center w-full justify-center'>
                    <Icons.SearchIcon />
                    <span className='ml-1'>Search</span>
                  </div>
                }
                onClick={handleRedirectClick}
              />
            </ComboboxGroupedButtons>
          </div>
        );
      }}
    </Filters>
  );
};
