/**
 * !!! Add Preact island components here. No Astro files can be imported into this file !!!
 *
 * Usage:
 *
 * import Islands from "@components/islands.ts";
 * <Islands.AccordionItem />
 */
import { AccordionItem } from "@components/Accordians/AccordionItem";
import { CardWithAccordionItem } from "@components/Cards/CardWithAccordionItem/index.tsx";
import { Combobox } from "@components/ComboBoxes/Combobox/index.tsx";
import { RoundedComboboxBtn } from "@components/ComboBoxes/ComboboxBtn/index.tsx";
import { ComboboxGroupButton } from "@components/ComboBoxes/ComboboxButton/index.tsx";
import { ComboboxGroupedButtons } from "@components/ComboBoxes/ComboboxButtonGroup/index.tsx";
import { ComboboxButtonWrapper } from "@components/ComboBoxes/ComboboxButtonWrapper/index.tsx";
import { ComboboxListbox } from "@components/ComboBoxes/ComboboxListbox/index.tsx";
import { ComboboxModal } from "@components/ComboBoxes/ComboboxModal/index.tsx";
import { ComboboxModalProvider } from "@components/ComboBoxes/ComboboxModalProvider/index.tsx";
import { ComboboxMultiSelect } from "@components/ComboBoxes/ComboboxMultiSelect/index.tsx";
import { ComboboxButtonMultiSelect } from "@components/ComboBoxes/ComboboxButtonMultiSelect/index.tsx";
import { ComboboxSearchInput } from "@components/ComboBoxes/ComboboxSearchInput/index.tsx";
import { FadeIn } from "@components/Carousels/FadeIn/index.tsx";
import { Filters } from "@components/Filters/Filters/index";
import { FilterComponent } from "@components/Filters/FilterComponent/index.tsx";
import { FilterComponentGroup } from "@components/Filters/FilterComponentGroup/index.tsx";
import { FilterSearchBtn } from "@components/Filters/FilterSearchBtn/index.tsx";
import { LinksRenderer } from "@components/LinksRenderer/index.tsx";
import { MixedCarouselDisplay } from "@components/Carousels/MixedCarousel/index.tsx";
import { QuoteCarouselDisplay } from "@components/Carousels/QuoteCarousel/index.tsx";
import {
  ScrollSnapCarouselContainer,
  ScrollSnapCarouselItem,
} from "@components/Carousels/ScrollSnapCarousel/index.tsx";
import { SectionHeader } from "@components/SectionElements/SectionHeader";
import {
  MultiSelectListOption,
  SingleSelectListOption
} from "@components/ComboBoxes/ComboboxListOption/index.tsx";
import { Slide } from "@components/Sliders/Slide/index.tsx";
import { Slider } from "@components/Sliders/Slider/index.tsx";
import { TestimonialMobile } from "@components/Testimonials/TestimonialMobile/index.tsx";
import { Testimonials } from "@components/Testimonials/Testimonials/index.tsx";
import { TextReplace} from "@components/Text/TextReplace/index.tsx";
import { TextsComponent } from "@components/Text/TextsComponent/index.tsx";
import { TitleText } from "@components/Text/Typography/index.tsx";

const PreactComponents = {
  AccordionItem,
  CardWithAccordionItem,
  Combobox,
  RoundedComboboxBtn,
  ComboboxGroupButton,
  ComboboxGroupedButtons,
  ComboboxButtonMultiSelect,
  ComboboxButtonWrapper,
  ComboboxListbox,
  ComboboxModal,
  ComboboxModalProvider,
  ComboboxMultiSelect,
  ComboboxSearchInput,
  FadeIn,
  Filters,
  FilterComponent,
  FilterComponentGroup,
  FilterSearchBtn,
  LinksRenderer,
  MixedCarouselDisplay,
  MultiSelectListOption,
  QuoteCarouselDisplay,
  ScrollSnapCarouselContainer,
  ScrollSnapCarouselItem,
  SectionHeader,
  SingleSelectListOption,
  Slide,
  Slider,
  TestimonialMobile,
  Testimonials,
  TextReplace,
  TextsComponent,
  TitleText,
};

export default PreactComponents;
