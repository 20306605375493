import type { FC, ReactNode } from 'preact/compat'
import { useRef, useState, useEffect } from 'preact/hooks'
import { useClickOutside } from '@hooks/useClickOutside'
import { ComboboxListbox } from '../ComboboxListbox'
import { ComboboxGroupButton } from '../ComboboxButton'
import { ComboboxSearchInput } from '../ComboboxSearchInput'
import { MultiSelectList } from './MultiSelectList'

export type MultiSelectListContainerProps = {
  optional?: boolean
  placeholder?: string
  label?: string
  isMobile?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
  buttonWrapperClass?: string
  variant?: 'contained' | 'outlined'
  selectLater?: {
    name: string
    label: string
    selected?: boolean
  }
  selectedItems?:
    | {
        label: string
        name: string
      }[]
    | null
  data: {
    name: string
    label: string
    searched?: boolean
    selected?: boolean
    filtered?: boolean
  }[]
  open?: boolean
  handleOpenClick?: (open: boolean) => void
  handleSelectLater?: (selected: boolean) => void
  handleGetSelectedItems?: (
    items: {
      label: string
      name: string
      selected: boolean
    }[]
  ) => void
  handleGetSelectedItem?: (item: {
    label: string
    name: string
    selected: boolean
  }) => void
}
export const MultiSelectListContainer: FC<MultiSelectListContainerProps> = ({
  data,
  handleGetSelectedItems,
  optional,
  handleOpenClick,
  open,
  placeholder,
  label,
  selectedItems,
  isMobile,
  selectLater,
  handleSelectLater,
  startIcon,
  endIcon,
  variant = 'outlined',
  buttonWrapperClass,
}) => {
  const comboBoxRef = useRef(null)
  const [searchTerm, setSearchTerm] = useState('')

  const [items, setItems] = useState<
    {
      label: string
      name: string
      selected: boolean
      searched?: boolean
      filtered?: boolean
    }[]
  >(
    data.map((_item) => ({
      ..._item,
      selected: false
    }))
  )

  const handleClick = () => {
    handleOpenClick?.(!open)
  }
  const handleClose = () => {
    handleOpenClick?.(false)
  }

  useClickOutside(comboBoxRef, () => {
    if (!isMobile) {
      handleClose()
    }
  })

  const handleSelectOption = (
    option: { name: string; label: string },
    checked: boolean
  ) => {

    let updatedItems = []
    if (checked) {
      updatedItems = items.map((_item) => ({
        ..._item,
        selected: _item.name === option.name ? true : _item.selected
      }))
      setItems(updatedItems)
    } else {
      updatedItems = items.map((_item) => ({
        ..._item,
        selected: _item.name === option.name ? false : _item.selected
      }))
      setItems(updatedItems)
    }
    const selected = updatedItems.filter((_item) => _item.selected)
    handleGetSelectedItems?.(selected)
  }

  const handleSearchChange = (term: string) => {
    setSearchTerm(term)
    if (term) {
      const filteredItems = items.map((_item) => {
        if (_item.label.toLowerCase().includes(term.toLowerCase())) {
          return {
            ..._item,
            searched: true
          }
        }

        return {
          ..._item,
          searched: false
        }
      })
      setItems(filteredItems)
    } else {
      setItems(items.map((_item) => ({ ..._item, searched: false })))
    }
  }

  useEffect(() => {
    if (selectedItems) {
      if (!selectedItems.length && selectLater?.selected) {
        setItems(items.map((_item) => ({ ..._item, selected: false })))
      } else {
        setItems(
          items.map((_item) => {
            const exists = selectedItems.find(
              (_selectedItem) => _selectedItem.name === _item.name
            )
            if (exists) {
              return { ..._item, selected: true }
            }
            return {
              ..._item
            }
          })
        )
      }
    }
  }, [selectedItems])

  return (
    <ComboboxGroupButton
      placeholder={placeholder}
      buttonWrapperClass={buttonWrapperClass}
      label={label}
      ref={comboBoxRef}
      onClick={handleClick}
      open={open}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant}
      labels={items
        .filter((_item) => _item.selected)
        .map((_item) => _item.label)}
    >
      {({ open }) => {
        {
          return open ? (
            <ComboboxListbox
              handleClose={handleClose}
              placeholder={placeholder}
              optional={optional}
              isMobile={isMobile}
              selectLater={selectLater}
              handleSelectLater={handleSelectLater}
              disableSelectLater
            >
              <ComboboxSearchInput handleSearchChange={handleSearchChange} />
              <MultiSelectList
                items={items}
                handleSelectOption={handleSelectOption}
                searchTerm={searchTerm}
              />
            </ComboboxListbox>
          ) : null
        }
      }}
    </ComboboxGroupButton>
  )
}
